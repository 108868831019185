/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./main.scss"
import { Link } from "gatsby"
// import { useStaticQuery, graphql, Link } from "gatsby"

const Layout = ({ children, hasHeader = true, hasFooter = true }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <main className="c-main">
          {children}
      </main>

      {hasFooter && <footer className="c-footer">
        
          <div className="container">
            <div className="columns is-centered" style={{ maxWidth: '100vw' }}>
              <div className="column is-half has-text-centered">
                © {new Date().getFullYear()} <Link to="/">Vancho Stojkov</Link>
              </div>
            </div>
          </div>
      </footer>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
