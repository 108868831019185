import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      avatarImage: file(relativePath: { eq: "avatar.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 160, placeholder: TRACED_SVG, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout hasHeader={false} hasFooter={false}>
      <Seo title="Vancho Stojkov" />

      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <GatsbyImage
              image={data.avatarImage.childImageSharp.gatsbyImageData}
              alt="Vancho Stojkov"
              className="hp-avatar"
              imgStyle={{ borderRadius: "50%" }}
            />
            <h1 className="title is-2">Vancho Stojkov</h1>
            <h2 className="subtitle is-4">software architect</h2>

            <p>passionate about performance {"&"} usability</p>

            <p>
              <br />
              <Link to="/about">about</Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
